import React from 'react';
import { graphql, Link } from 'gatsby';

import {
    IntroHolder,
    IntroQuote,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const ShoghiEffendi18971957 = ({ data }) => (
    <InnerPage
        title="Shoghi Effendi (1897-1957)"
        description="Shoghi Effendi – der Hüter des Bahá’í-Glaubens"
    >
        <IntroHolder
            title="Shoghi Effendi (1897-1957)"
            subtitle="Shoghi Effendi – der Hüter des Bahá’í-Glaubens"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item': 'Shoghi Effendi (1897-1957)',
            }}
        >
            <IntroQuote>
                Alle staunen über die Fülle seiner Errungenschaften und seine
                Führungsweise, eine Führungsweise, die in einer Handvoll
                gewöhnlicher Menschen ungeahnte Fähigkeiten und Kräfte weckte.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Shoghi Effendi (1897–1957) war der Urenkel Bahá’u’lláhs. Von
                    Bahá’u’lláh dazu autorisiert, hinterließ ‘Abdu’l-Bahá 1921
                    in Seinem Testament genaue Anweisungen für die weitere
                    Entwicklung der Bahá’í-Gemeindeordnung. Er verwies auf das
                    Hütertum sowie auf das Universale Haus der Gerechtigkeit als
                    oberste Institution der weltweiten Bahá’í-Gemeinde und
                    erläuterte deren Funktionen.{' '}
                </p>
                <div>
                    <p>
                        ‘Abdu’l-Bahá ernannte in Seinem Testament Seinen
                        ältesten Enkel, Shoghi Effendi, zu Seinem Nachfolger als
                        Hüter der Sache Gottes. Diese Ernennung verlieh ihm die
                        alleinige Autorität, die Heiligen Schriften und Lehren
                        des Glaubens zu interpretieren und zu erklären.
                        <br />
                        Shoghi Effendi bewirkte während seiner Amtszeit
                        (1921–1957) die maßgebliche Gestaltung der
                        Bahá’í-Gemeindeordnung und die weltweite Verbreitung des
                        Glaubens. Auch Themen wie die Organisation, Wahl und
                        Wirkungsweise der Bahá’í-Institutionen wurden von ihm
                        fokussiert vorangetragen.
                        <br />
                        Neben seiner umfangreichen Korrespondenz mit Gemeinden
                        und Gläubigen übersetzte er zahlreiche Heilige Schriften
                        aus dem Persischen bzw. Arabischen ins Englische
                        <br />
                        Im November 1957 hatte ihn seine Arbeit nach London
                        geführt. Dort erkrankte er an der Grippe und verstarb im
                        Alter von 60 Jahren. Durch sein Lebenswerk war die
                        lokale und nationale institutionelle Struktur des
                        Glaubens so weit entwickelt, dass sechs Jahre später die
                        erste Wahl des Universalen Hauses der Gerechtigkeit
                        stattfinden konnte.
                    </p>
                </div>
                <h2>
                    Shoghi Effendis Schriften über die Weltordnung Bahá’u’lláhs
                </h2>
                <div>
                    <p>
                        Als Hüter des Bahá’í-Glaubens setzte Shoghi Effendi in
                        seinen Schriften und Übersetzungen einen verbindlichen
                        Maßstab für das Verständnis der Bahá’í-Lehren. Er
                        übersetzte grundlegende Werke Bahá’u’lláhs in ein
                        ausgezeichnetes Englisch. Diese Texte bilden neben den
                        Originalschriften die Grundlage für Übersetzungen in
                        zahlreiche andere Sprachen.
                        <br />
                        Seine Schriften erläutern die Bedeutung der
                        Bahá’í-Gemeindeordnung und ihren Beitrag zu der von
                        Bahá’u’lláh vorausgeschauten geeinten Welt. Sie helfen
                        dem Leser, das Wachstum und die Entwicklung des Glaubens
                        im Kontext aktueller Weltereignisse besser zu verstehen.
                        <br />
                        Während seiner Amtszeit entwarf er das religiöse und
                        administrative Zentrum des Glaubens am Hang des Karmel
                        in Haifa. Er vollendete dort auch die unter ‘Abdu’l-Bahá
                        begonnene Errichtung des Schreins des Báb – einer der
                        heiligsten Orte für die Bahá’í – und begann die Anlage
                        der umliegenden Bahá’í-Gärten. Shoghi Effendi gestaltete
                        ebenfalls den Schrein Bahá’u’lláhs und die ihn
                        umgebenden Gärten in Bahjí bei Akka in der Nähe von
                        Haifa.
                    </p>
                    <div>
                        <p>
                            <img
                                alt=""
                                src={data.collage.childImageSharp.fluid.src}
                                className="aligncenter"
                            />
                        </p>
                    </div>
                </div>
                <p>
                    Shoghi Effendi förderte auch die weitere Errichtung von
                    Bahá’í-Häusern der Andacht auf verschiedenen Kontinenten,
                    nachdem bereits in der Wirkungszeit von ‘Abdu’l-Bahá in
                    Ishqabad (heute in Turkmenistan) und Wilmette (bei Chicago)
                    die ersten Häuser der Andacht errichtet worden waren. Auf
                    Shoghi Effendi geht auch der Bau des{' '}
                    <Link to="/haus-der-andacht/">
                        Europäischen Hauses der Andacht
                    </Link>{' '}
                    in Langenhain nahe Frankfurt am Main zurück.
                </p>
                <Reference>
                    <p>1. David Hofman, Autor und Fernsehmoderator</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default ShoghiEffendi18971957;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "4965724-master.jpg" }) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "shoghi-effendi-collage.png" }) {
            ...fluidImage
        }
    }
`;
